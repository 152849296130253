<template>
  <v-row justify="center">
    <v-dialog v-model="shower" width="800px" scrollable persistent>
      
      <v-card class="pa-3 text-center">
        <v-card-text style="height:450px">
        <div v-if="checkadmin_ == true" class="py-3" style="font-size:20px">{{ $t("myinboxPage.contactadmin") + $t("myinboxPage.contactadmin1")}}</div>
        <!-- <div v-if="checkadmin_ == true" class="py-1">{{ $t("myinboxPage.contactadmin1") }}</div> -->
        <div v-if="checkadmin_ == true" class="py-1" style="color:red">{{ $t("myinboxPage.contactadmin2") }}</div>
        <div  class="pt-3" v-if="checkadmin_ == true">
          <span class="pa-3 text-left">{{ $t("admin_business") }}</span>
          <v-chip small class="ma-1" v-for="item in listadmin" :key="item">{{
            $t("default") === "th" ? item.name_th : item.name_en
          }}</v-chip>
        </div>
        <div class="pt-3" v-else>
          <span class="pa-3  text-center" style="font-size:20px">{{ $t("donthaveadminbusiness") }}</span>
          <br/>
          <span class="pa-3  text-center" style="color:red">{{  $t("default") === "th" ? listadminfalse  : lisyadminfalse_en}}</span>
        </div>
        <br />
        <div>
          <img
            :src="require('@/assets/img/1.jpg')"
            width='600px'
          >
        </div>
        <br />
        <div>
          <img
            :src="require('@/assets/img/2.jpg')"
            width='600px'
          >
        </div>
        <br />
        <div>
          <img
            :src="require('@/assets/img/3.jpg')"
            width='600px'
          >
        </div>


        <!-- <v-list dense>
        <v-list-item
          v-for="(item, i) in listadmin"
          :key="i"
        >
          <v-list-item-content>
            <v-list-item-title v-text="item.name_th"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

    </v-list> -->
  </v-card-text>
  <v-card-actions class="justify-center">
    <v-btn color="red" outlined @click="$emit('close')">{{
      $t("admin.close")
    }}</v-btn>
  </v-card-actions>

      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "@/globalFunctions/generateAuthorize";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  props: ["show"],
  data: function() {
    return {
      listadmin: [],
      checkadmin_:true,
      listadminfalse:"",
      lisyadminfalse_en:""
    };
  },
  watch: {
    shower: {
      immediate: true,
      handler(value) {
        if (value) {
          this.fn_adminname();
        }
      },
    },
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        // console.log("this.show ",this.show);
        // if (this.show === true) {
        //   console.log("----งง");
        //   this.fn_adminname();
        // }
        return this.show;
      },
      set(value) {
        if (!value) {
        }
      },
    },
  },
  methods: {
    async fn_adminname() {
      console.log("เข้ามั้ย fn_adminname");
      var payload = {
        business_id: this.dataAccountActive.business_info.business_id,
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/get_consent_admin",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            this.checkadmin_ = true;
            console.log(response.data.data);
            this.listadmin = response.data.data;
          } else {
            this.checkadmin_ = false;
            console.log(response.data.errorMessage);
            this.listadminfalse = response.data.errorMessage;
            this.lisyadminfalse_en = "Admin Business was not found, please send an e-mail to Nockkc@inet.co.th. The story is accepting the Consent Inbox (with company name taxpayer identification number and contact information such as name, phone number) Thank you";
          }
        })
        .catch((error) => {
          console.log("error", error);
          Toast.fire({
            icon: "error",
            title: error,
          });
        });
    },
  },
};
</script>
<style></style>
